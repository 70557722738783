import { Box, Text, Flex } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { SimpleDashboard } from 'layouts/dash/types';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from 'services/api';
import EstresseGraph from './components/EstresseGraph';
import { CountResponses } from './types';

export default function Estresse(props: SimpleDashboard) {
  const { userId } = props;
  const [info, setInfo] = useState<CountResponses | null>(null);

  useEffect(() => {
    api
      .post(`/tableresponse/evaluations`, { evaluations: [userId] })
      .then(({ data }) => setInfo(data))
      .catch(() => toast.error('Erro ao buscar dados'));
  }, [userId]);

  if (!info) return null;

  return (
    <Flex direction="column" w="100%" h="100%" justifyContent="center" alignItems="center">
      <EstresseGraph>
        <svg
          style={{
            position: 'absolute',
            bottom: '50%',
            right: '50%',
            rotate: `${-75 + (info?.groupTotal / 56) * 240}deg`,
            transformOrigin: 'bottom right',
          }}
          xmlns="http://www.w3.org/2000/svg"
          width="2.5rem"
          height="2.5rem"
          viewBox="0 0 15 15"
        >
          <path
            fill="currentColor"
            d="M4.5 0h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .854.354L2.5 3.207l11.646 11.647l.708-.708L3.207 2.5L4.854.854A.5.5 0 0 0 4.5 0"
          />
        </svg>

        <Box
          w="50%"
          h="2rem"
          position="absolute"
          right="50%"
          bottom="calc(50% - 1rem)"
          transformOrigin="center right"
          transform={`rotate(${-30 + (info?.groupTotal / 56) * 240}deg)`}
        >
          <motion.div
            initial={{ opacity: 0, rotate: '0deg' }}
            animate={{ opacity: 1, rotate: `${-(-30 + (info?.groupTotal / 56) * 240)}deg` }}
            transition={{ duration: 0 }}
          >
            <Text fontSize="0.75rem" fontWeight="700" textAlign="center">
              {`${info?.responses?.[0]?.name} ( ${info?.groupTotal} )`}
            </Text>
          </motion.div>
        </Box>
      </EstresseGraph>
    </Flex>
  );
}

import { Flex, Text, Button, Box } from '@chakra-ui/react'
import { SimpleScannerDashboard } from 'layouts/dash/types'
import React, { useEffect, useState } from 'react'
import api from 'services/api'
import virtues from './virtues.json'

type TViaArea = SimpleScannerDashboard & {
  mini?: boolean
}

type TResultVia = {
  via: string[]
  virtues: string[]
}

const getBgColor = (index: number) => {
  if (index < 5) return '#ffc000'
  else if (index < 10) return '#fee599'
  else if (index < 19) return '#bfbfbf'
  else if (index < 24) return '#7f7f7f'
  return undefined
}

export default function ViaChart(props: TViaArea) {
  const { userId, mini, chartRefs, scanner, reportOpen } = props

  const [data, setData] = useState<TResultVia>({ via: [], virtues: [] })
  const [mode, setMode] = useState(0)

  const handleToggleMode = () => setMode((prev) => (prev === 1 ? 0 : 1))

  useEffect(() => {
    if (userId === '') return
    api
      .post(`tableresponse/evaluations/`, { evaluations: [userId] })
      .then(({ data }) => setData(data))
      .catch(() => null)
  }, [userId])

  if (!userId || userId === '' || !data.via.length) return null

  return (
    <Flex direction="column" width="100%" overflow="hidden">
      {!mini && (
        <Button alignSelf="start" flexShrink="0" colorScheme="yellow" onClick={handleToggleMode} w="max-content">
          {mode === 1 ? 'Forças' : 'Virtudes'}
        </Button>
      )}

      {(reportOpen || mode === 0) && (
        <Flex justifyContent="center" alignItems="center" flexDirection="column" ref={chartRefs?.[0]}>
          <Flex
            wrap="wrap"
            flexDirection={mini ? 'row' : 'column'}
            marginY="0.625rem"
            height={
              mini ? 'auto' : scanner ? '21.875rem' : ['auto', 'auto', 'auto', '43.75rem', '29.375rem', '21.875rem']
            }
            alignItems="center"
            justifyContent="center"
            rowGap="1rem"
            transform={mini ? 'scale(0.75)' : 'scale(1)'}
          >
            {data.via.map((str, index) => {
              if (mini && index < 5) {
                return (
                  <Flex key={str} flexShrink="0" ml="0.625rem" gap="0.625rem" width="13.75rem" justifyContent="end">
                    <Text fontSize="1.4375rem">{index + 1}°</Text>
                    <Flex
                      bgColor={getBgColor(index)}
                      width="12.5rem"
                      height="2.5rem"
                      borderRadius="0.3125rem"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Text fontWeight="600">{str}</Text>
                    </Flex>
                  </Flex>
                )
              }
              if (mini) return null
              return (
                <Flex key={str} mx="1rem" flexShrink="0" gap="0.625rem" width="15.625rem" justifyContent="end">
                  <Text fontSize="1.4375rem">{index + 1}°</Text>
                  <Flex
                    bgColor={getBgColor(index)}
                    width="12.5rem"
                    height="2.5rem"
                    borderRadius="0.3125rem"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Text fontWeight="600">{str}</Text>
                  </Flex>
                </Flex>
              )
            })}
          </Flex>
          <Text fontSize="1.25rem" fontWeight="800">
            Resultado do teste Via
          </Text>
          {!scanner ? (
            <Text
              fontWeight="400"
              align="center"
              marginTop="0.625rem"
              fontSize="0.875rem"
              maxWidth="700"
              color="gray.500"
              as="i"
            >
              {`Relatório de Forças de Caráter é uma ordem de classificação de forças.
        As 5 primeiras forças são chamadas de "forças de assinatura". As 5
        últimas são pontos fortes subdesenvolvidos e menos utilizados em
        comparação com outras forças, e não são consideradas como pontos fracos.`}
            </Text>
          ) : null}
        </Flex>
      )}
      {(reportOpen || mode === 1) && (
        <Box ref={chartRefs?.[1]}>
          <Flex
            width="73.25rem"
            textAlign="center"
            bg="#bfbfbf"
            paddingBlock="0.25rem"
            fontSize="1.75rem"
            fontWeight="900"
            justifyContent="center"
          >
            ANÁLISE DAS VIRTUDES
          </Flex>
          <Flex gap="0.25rem" marginTop="0.25rem">
            {virtues.map((macro, index) => {
              return (
                <Flex key={index} direction="column" gap="0.25rem">
                  <>
                    <Flex
                      width="12rem"
                      height="8rem"
                      bg={'#bfbfbf'}
                      justifyContent="center"
                      alignItems="center"
                      textAlign="center"
                      padding="0.25rem"
                      direction="column"
                    >
                      <Text fontSize="1.2rem" fontWeight="800">
                        {macro.title}
                      </Text>
                      <Text fontStyle="italic" fontSize="0.7rem">
                        {macro.subtitle}
                      </Text>
                    </Flex>
                    {macro.list.map((micro, index) => {
                      return (
                        <Flex
                          width="12rem"
                          height="6rem"
                          key={index}
                          bg={micro && data.virtues.slice(0, 5).includes(micro) ? macro.color : '#d9d9d9'}
                          justifyContent="center"
                          alignItems="center"
                          textAlign="center"
                          padding="0.625rem"
                          fontSize="1.25rem"
                        >
                          {micro}
                        </Flex>
                      )
                    })}
                  </>
                </Flex>
              )
            })}
          </Flex>
        </Box>
      )}
    </Flex>
  )
}
